<template>
    <section :id="cmsBlock.anchorTag" class="component-container" :class="cmsBlock.textColumnsStyle">
        <div class="text-columns">
            <div class="text-column" v-for="column in cmsBlock.textColumns" :key="column.id">
                <h3>{{ localizeAlt(column, 'title') }}</h3>
                <div v-html="md(localizeAlt(column, 'text'))"></div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: 'TextColumns',

        props: [
            'cmsBlock'
        ]
    }
</script>

<style lang="scss" scoped>
    .component-container {
        .text-columns {
            max-width: $container-width;
            margin: 0 auto;
        }
    }
</style>
